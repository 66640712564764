import { Currency, currencyToSymbol } from "src/common/currencies";

export const convertCentsToCurrency = (
  amount: number,
  currency: Currency,
  {
    showEmptyFractionalPart = true,
    showLeadingZero = true,
  }: { showEmptyFractionalPart?: boolean; showLeadingZero?: boolean } = {}
) =>
  `${currencyToSymbol[currency]}${Number(amount / 100)
    .toFixed(showEmptyFractionalPart || amount % 100 !== 0 ? 2 : 0)
    .toString()
    .padStart(showLeadingZero ? 2 : 1, "0")}`;
