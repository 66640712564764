import { get, camelCase, values, isEmpty } from "lodash";
import {
  useUserShowSelf,
  useUserUpdateSelf,
} from "@gocardless/api/dashboard/user";
import {
  BillingContactListResponseBody,
  useBillingContactList,
  useBillingContactUpdate,
  useBillingContactCreate,
} from "@gocardless/api/dashboard/billing-contact";
import {
  DeveloperContactListResponseBody,
  useDeveloperContactList,
  useDeveloperContactCreate,
  useDeveloperContactUpdate,
} from "@gocardless/api/dashboard/developer-contact";
import { HTTPError } from "@gocardless/api/utils/api";
import { CreditorType } from "@gocardless/api/dashboard/types";

import { UseSetupPage } from "../routing/types";
import { usePMOImprovements } from "../common/hooks/usePMOImprovements";

import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { Route } from "src/common/routing";
import { CountryCodes } from "src/common/country";
import { useOrganisation } from "src/libraries/organisation/hooks";

export interface UseContacts extends UseSetupPage {
  billingContact: Contact;
  developerContact: Contact;
  submitContacts: (contact: ContactDetailsConfig) => Promise<void>;
  geo: CountryCodes;
}

export interface ContactDetailsConfig {
  billingGivenName: string;
  billingFamilyName: string;
  billingEmail: string;
  billingPhoneNumber: string;
  developerGivenName?: string;
  developerFamilyName?: string;
  developerEmail?: string;
}

interface Contact {
  id: string;
  email: string;
  familyName: string;
  givenName: string;
  phoneNumber: string;
  locale: string;
  createdAt: string;
}

const parseContact = (
  prefix: string,
  list:
    | BillingContactListResponseBody
    | DeveloperContactListResponseBody
    | undefined
) => {
  const defaultContact: Contact = {
    id: "",
    email: "",
    familyName: "",
    givenName: "",
    phoneNumber: "",
    locale: "",
    createdAt: "",
  };
  const contact = get(list, `${prefix}_contacts[0]`, defaultContact);
  return Object.fromEntries(
    Object.entries(contact).map(([key, value]) => [camelCase(key), value])
  ) as Contact;
};

export function useContacts({
  onBillingContactSuccess,
  onDeveloperContactSuccess,
  onError,
}: {
  onBillingContactSuccess?: () => void;
  onDeveloperContactSuccess?: () => void;
  onError?: (error: HTTPError) => void;
} = {}): UseContacts {
  const { data: billingContacts, revalidate: revalidateBilling } =
    useBillingContactList();
  const { data: devContacts, revalidate: revalidateDeveloper } =
    useDeveloperContactList();
  const creditor = usePrimaryCreditor();
  const { data: userData } = useUserShowSelf();
  const organisation = useOrganisation();

  const billingContact = parseContact("billing", billingContacts);
  const developerContact = parseContact("developer", devContacts);

  if (!billingContact.email) {
    billingContact.email = userData?.users?.email || "";
  }

  const { isFlagEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.US_VERIFICATION_IMPROVEMENTS,
  });

  const usVerificationImprovementsEnabled =
    isFlagEnabled && creditor?.geo === CountryCodes.US;

  if (usVerificationImprovementsEnabled) {
    if (!billingContact.givenName && userData?.users?.given_name) {
      billingContact.givenName = userData?.users?.given_name;
    }
    if (!billingContact.familyName && userData?.users?.family_name) {
      billingContact.familyName = userData?.users?.family_name;
    }
  }

  const [updateBillingContact] = useBillingContactUpdate(billingContact.id, {
    onSuccess: () => revalidateBilling().then(onBillingContactSuccess),
    onError,
  });

  const [createBillingContact] = useBillingContactCreate({
    onSuccess: () => revalidateBilling().then(onBillingContactSuccess),
    onError,
  });

  const [updateDeveloperContact] = useDeveloperContactUpdate(
    developerContact.id,
    {
      onSuccess: () => revalidateDeveloper().then(onDeveloperContactSuccess),
      onError,
    }
  );

  const [createDeveloperContact] = useDeveloperContactCreate({
    onSuccess: () => revalidateDeveloper().then(onDeveloperContactSuccess),
    onError,
  });

  const [updateUser] = useUserUpdateSelf();
  const geo = creditor?.geo as CountryCodes;

  const submitContacts = async (contact: ContactDetailsConfig) => {
    const {
      billingGivenName,
      billingFamilyName,
      billingEmail,
      billingPhoneNumber,
      developerGivenName,
      developerFamilyName,
      developerEmail,
    } = contact;

    const reqs = [];
    const billingParams = {
      given_name: billingGivenName,
      family_name: billingFamilyName,
      email: billingEmail,
      phone_number: billingPhoneNumber,
    };

    reqs.push(
      billingContact.id
        ? updateBillingContact(billingParams)
        : createBillingContact({ ...billingParams, locale: "en-GB" })
    );

    if (developerGivenName && developerFamilyName && developerEmail) {
      const params = {
        given_name: developerGivenName,
        family_name: developerFamilyName,
        email: developerEmail,
      };

      reqs.push(
        developerContact.id
          ? updateDeveloperContact(params)
          : createDeveloperContact({ ...params, locale: "en-GB" })
      );
    } else {
      // Removes developer details
      reqs.push(developerContact.id && updateDeveloperContact({}));
    }

    if (!userData?.users?.family_name && !userData?.users?.given_name) {
      reqs.push(
        updateUser({
          family_name: billingFamilyName,
          given_name: billingGivenName,
        })
      );
    }

    await Promise.all(reqs);
  };
  const isPartnerMerchantOnboardingImprovementsEnabled =
    usePMOImprovements(organisation);

  const completed = values(billingContact).every((v) => !isEmpty(v));

  // Individuals often use the same details on the About You and Contact Details pages.
  // For PMO experiment, we are creating contact details when submitting the About You page and bypassing this page.
  const bypass =
    completed &&
    isPartnerMerchantOnboardingImprovementsEnabled &&
    creditor?.creditor_type === CreditorType.Individual;

  return {
    loaded: !!(billingContacts && devContacts),
    completed,
    bypass,
    route: Route.ContactDetails,
    billingContact,
    developerContact,
    submitContacts,
    geo,
  };
}
